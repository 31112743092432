import { useMemo } from "react";

import { ColDef } from "ag-grid-community";
import i18next from "i18next";

import {
  BSCoreServicesDtoMarketplaceOrderDto,
  BSCoreServicesDtoMarketplaceOrderProductDto,
} from "api/reactQuery/querySchemas";
import { MarketplacePalletsTypes } from "components/Utils/enums";
import { COLUMN_TYPE_NAMES } from "constants/agGridConstants";

const useColDefs = () => {
  const productColumnDefs = useMemo<
    ColDef<BSCoreServicesDtoMarketplaceOrderProductDto>[]
  >(
    () => [
      { field: "productName", headerName: i18next.t("product_name") },
      { field: "productPrice", headerName: i18next.t("product_price") },
      {
        field: "productUnitsInPallet",
        headerName: i18next.t("product_units_in_pallet"),
      },
      {
        field: "pallet.type",
        filter: "agTextColumnFilter",
        filterParams: {
          textMatcher: ({ value, filterText }) => {
            const formattedValue = MarketplacePalletsTypes[value] || value;

            return formattedValue
              .toLowerCase()
              .includes(filterText.toLowerCase());
          },
        },
        headerName: i18next.t("pallet_type"),
        valueFormatter: (params) =>
          MarketplacePalletsTypes[params.value] || params.value,
      },
      {
        field: "weightOfOrderUnit",
        headerName: i18next.t("weight_of_order_unit"),
      },
      {
        field: "orderedQuantitiesInPallets",
        headerName: i18next.t("ordered_quantities_in_pallets"),
      },
      {
        field: "orderedQuantitiesInUnits",
        headerName: i18next.t("ordered_quantities_in_units"),
      },
      { field: "orderedUnits", headerName: i18next.t("ordered_units") },
      {
        field: "productVolumeInHL",
        headerName: i18next.t("product_volume_in_hl"),
      },
      {
        field: "totalBeerCostWithoutVatInBGN",
        headerName: i18next.t("total_beer_cost_without_vat_in_bgn"),
      },
      {
        field: "totalPackagingCostInBGN",
        headerName: i18next.t("total_packaging_cost_in_bgn"),
      },
      {
        field: "totalBeerAndPackagingCostWithVat",
        headerName: i18next.t("total_beer_and_packaging_cost_with_vat"),
      },
      { field: "productWeight", headerName: i18next.t("product_weight") },
    ],
    []
  );
  const orderColumnDefs = useMemo<
    ColDef<BSCoreServicesDtoMarketplaceOrderDto>[]
  >(
    () => [
      { field: "id", headerName: i18next.t("id") },
      { field: "distributorName", headerName: i18next.t("distributor_name") },
      { field: "depotName", headerName: i18next.t("depot_name") },
      {
        field: "dateCreated",
        headerName: i18next.t("date_created"),
        type: COLUMN_TYPE_NAMES.DATE_COLUMN,
      },
      {
        field: "deliveryDate",
        headerName: i18next.t("delivery_date"),
        type: COLUMN_TYPE_NAMES.DATE_COLUMN,
      },
      {
        field: "total.ttlVolumeInHL",
        headerName: i18next.t("ttl_volume_in_hl"),
      },
      {
        field: "total.ttlPackagingCostInBGN",
        headerName: i18next.t("packaging_cost_w_vat"),
      },
    ],
    []
  );

  return { orderColumnDefs, productColumnDefs };
};

export default useColDefs;
